import { MATRIX_TYPES } from './index'
import isMobile from '../../helpers/isMobile'

export const getFormTypeContentCls = (type: MATRIX_TYPES): number | false => {
	switch (type) {
		case 'single':
			return isMobile() ? 3300 : 3161
			break
		case 'match':
			return isMobile() ? 2000 : 2400
			break
		case 'match-business':
			return isMobile() ? 2000 : 2400
			break
		case 'children':
			return isMobile() ? 3100 : 2700
			break
		case 'finance':
			return isMobile() ? 1979 : 957
			break
		case 'self-brand':
			return isMobile() ? 1979 : 957
			break
		default:
			return false
	}
}
